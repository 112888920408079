.imageButton {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  animation: fadein 300ms ease-in;
}

.imageButton:focus {
  opacity: 0.6;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
